<template>
  <TaskPageWrapper task-type="Acknowledge" @formDirtyChange="formDirty = $event" />
  <ConfirmDialog ref="confirmDialog" />
</template>

<script>
import ConfirmDialog from '@/components/Modals/ConfirmDialog'
import TaskPageWrapper from "@/components/TaskPages/TaskPageWrapper.vue";

export default {
  components: {
    TaskPageWrapper,
    ConfirmDialog,
  },
  async beforeRouteLeave() {
    if (this.formDirty) {
      await this.$refs.confirmDialog
        .confirm({
          text:
            'You have unsaved changes.\n' +
            'Are you sure, you want to leave the page?',
          confirmText: 'Leave',
          cancelText: 'Cancel',
          reverse: true,
        })
        .then((response) => {
          if (response) {
            return true
          } else {
            return Promise.reject(false)
          }
        })
    } else {
      return true
    }
  },
  data() {
    return {
      formDirty: false
    }
  }
}
</script>
